import React, { useState } from "react";
//= Layout
import MainLayout from "layouts/Main";
//= Components
import Navbar from "components/Navbars/ITCreativeNav";
import ModalVideo from "react-modal-video";
import ChatBanner from "components/Software/ChatBanner";
import Footer from "components/Saas/Footer";
import { Link } from "gatsby";
import Blog9 from "components/Software/Blog9";


const AgricFinance = () => {

  const [isOpen, setOpen] = useState(false);

  const openVideo = (e) => {
    e.preventDefault();
    setOpen(true);
  }
  return (
    <MainLayout>
      <Navbar />
      <header className="style-3 overflow-hidden" data-scroll-index="0">
      <div className="container">
        <div className="content section-padding">
          <div className="row">
            <div className="col-lg-6">
              <div className="info" style={{ background: '#00000000'  }}>
                <h1 className="h1">Power Your Agribusiness with   <span>Flexible Financing  </span></h1>
                <p className="p">Access the tools and equipment to grow your agricultural business </p>                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-img ">       
        <img src="/assets/img/about/style_3_1.png" style={{ maxWidth: '30%'}} alt="" />   
        <img
                    src="/assets/img/about/AGRIC FINANCE.png"
                    alt=""
                   
                  />
        
      </div>
    </header>
      <main>
        <section
          className="about section-padding style-3"
          data-scroll-index="3"
        >
          <div className="container">
            <div className="row gx-0 justify-content-end">
              <div className="col-lg-6">
                <div className="img  ">
                  <img
                    src="/assets/img/about/AGRIC FINANCE.png"
                    alt=""
                    className="img-body "
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="">
                  <div className="section-head long-shape mb-40 style-3">
                    <h3>Agric Finance  </h3>
                  </div>
                  <p>
                  Boost your agricultural business with our Agric Asset Finance, designed to provide flexible financing for essential tools, machinery, and equipment. We understand the unique challenges faced by agribusinesses and offer tailored solutions to help you increase productivity, reduce costs, and improve profitability. With competitive interest rates, easy application, and quick approval, our Agric Asset Finance is here to support sustainable growth in the agricultural sector.
                  </p>
                  <div className="text mb-20 mt-20">
                    <h5>Requirements: </h5>
                    <ul>
                      <li>Registered Agribusiness or Cooperative</li>
                      <li>Business Plan or Project Proposal</li>
                      <li>Completed KYC verification</li>
                      <li>Valid Identification (National ID, Driver’s License, or Passport)</li>
                      <li>Bank Verification Number (BVN)</li>
                      <li>Completed KYC verification</li>
                    </ul>
                  </div>
                  <Link
                    to="/contact"
                    className="btn rounded-pill bg-blue2 sm-butn text-white mt-10"
                  >
                    <span>Apply for Agric Asset Finance</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Blog9 />
        <ChatBanner />
      </main>
      <Footer />
    </MainLayout>
  );
};

export const Head = () => {
  return (
    <>
      <title> Agric Asset Finance - Fund Your Agricultural Business</title>
<meta name="description" content=" Grow your agricultural business with our flexible financing. Enjoy Agric Asset Finance to boost your Agric business and increase your profits.
" />
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  );
};

export default AgricFinance;
